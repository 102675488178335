
import { computed, defineComponent, onMounted, reactive, ref ,watch} from "vue";
import { VMoney } from "v-money";
import useAlert from "@/composables/Alert";
import { ContentLoader } from "vue-content-loader";
import { useStore } from "vuex";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { servicoAdd, getDescricao, servicoView } from "@/services/CrudService";
import { Modal } from "bootstrap";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import ApiService from "@/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "servico-add",

  components: {
    ContentLoader,
    MessageModal,
  },

  setup() {
    const money = {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
    };

    const store = useStore();
    const animate = ref(false);
    const { showTimeAlert } = useAlert();
    const codConcessionaria = ref(0);
    const descricao: any = ref("");
    const modalBuscarPeca: any = ref({});
    const modalBuscarPecaMO: any = ref({});
    const route = useRoute();
    const showPeca = ref(false);
    const showMo = ref(false);

    const codServico = Number(route.params.codServico);

    store.watch(
      () => store.getters.concessionariaSelectedInfo,
      () => {
        codConcessionaria.value =
          store.getters.concessionariaSelectedInfo.codConcessionaria;
        descricoes(codConcessionaria.value);
      },
      { deep: true }
    );

    onMounted(async () => {
      if (codServico) {
        getServico(codServico);
      }

      if (store.getters.concessionariaSelectedInfo.codConcessionaria) {
        codConcessionaria.value =
          store.getters.concessionariaSelectedInfo.codConcessionaria;
        descricoes(codConcessionaria.value);
      }

      const refModal = document.getElementById("modal-buscarpeca");
      modalBuscarPeca.value = new Modal(refModal);

      const refModal2 = document.getElementById("modal-buscarpecamo");
      modalBuscarPecaMO.value = new Modal(refModal2);
    });

    let dominio_gestao = store.getters.layoutConfig(
      "distribuidor.distribuidor"
    );

    const formSize = ref("default");
    const ruleFormRef = ref<null | HTMLFormElement>(null);
    let loadingButton = ref(false);
    let loadingFullScreen = ref(false);

    let form: any = reactive({
      codServico: "",
      codConcessionaria: "",
      codCategoria: "",
      nome: "",
      tipo: "",
      valorCompra: "",
      valorPeca: "",
      valorMaoDeObra: "",
      valorTotal: "",
      valorPremiacao: "",
      tempoMaoObra: "",
      ordemExibicao: "",
      corColuna: "",
      situacao: 1,
      intServico: "",
      intServicoMO: "",
      codwynns: "",
      unidadescaixa: "",
      estoque: "",
      dataestoque: "",
      fornecedor: 1,
      ticketmedio: 1,
      codPersonalizacao: "",
      intGrupo: "",
      intSubGrupo: "",
      ultNfFaturada: "",
      ultNfDtFaturada: "",
      dataCadastro: "",
      dataAlteracao: "",
      valorHoraMO: ""
    });

    const showValorMo = ref(1);

    async function getServico(codServico) {
      animate.value = true;
      loadingFullScreen.value = true;
      let response = await servicoView(codServico);

      showMo.value = response.intServicoMO || removeCurrency(response.tempoMaoObra) !== 0 ? true : false;
      showPeca.value = response.intServico ? true : false;

      showValorMo.value = Number(response.tempoMaoObra) > 0 ? 2 : 1;  

      form.codServico = response.codServico;
      form.codConcessionaria = response.codConcessionaria;
      form.codCategoria = response.codCategoria;
      form.nome = response.nome;
      form.tipo = response.tipo;
      form.valorCompra = response.valorCompra;
      form.valorPeca = response.valorPeca;
      form.valorMaoDeObra = response.valorMaoDeObra;
      valorTotalServico.value = response.valorTotal;
      form.valorPremiacao = response.valorPremiacao;
      form.tempoMaoObra = response.tempoMaoObra;
      form.ordemExibicao = response.ordemExibicao;
      form.corColuna = response.corColuna;
      form.situacao = response.situacao;
      if (response.intServico) {
        const pecas = response.intServico;
        pecas.split("/").map((peca) => multiplesPeca.value.push(peca));
      }
      if (response.intServicoMO) {
        const servicoMo = response.intServicoMO;
        servicoMo.split("/").map((MO) => multiplesMo.value.push(MO));
      }
      form.codwynns = response.codwynns;
      form.unidadescaixa = response.unidadescaixa;
      form.estoque = response.estoque;
      form.dataestoque = moment(response.dataestoque, "DD/MM/YYYY");
      form.fornecedor = Number(response.fornecedor);
      form.ticketmedio = response.ticketmedio;
      form.codPersonalizacao = response.codPersonalizacao;
      form.intGrupo = response.intGrupo;
      form.intSubGrupo = response.intSubGrupo;
      form.ultNfFaturada = response.ultNfFaturada;
      form.ultNfDtFaturada = response.ultNfDtFaturada;
      form.dataCadastro = response.dataCadastro;
      form.dataAlteracao = response.dataAlteracao;
      form.valorHoraMO = response.valorHoraMO;

      animate.value = false;
      loadingFullScreen.value = false;
    }

    const rules = ref({
      valorCompra: [
        {
          required: true,
          message: "Valor da compra obrigatório",
          trigger: "change",
        },
      ],
      valorPeca: [
        {
          required: showPeca.value ? true : false,
          message: "Valor da peça obrigatório",
          trigger: "change",
        },
      ],
      codCategoria: [
        { required: true, message: "Categoria obrigatória", trigger: "change" },
      ],
      nome: [
        { required: true, message: "Nome obrigatório", trigger: "change" },
      ],
      tipo: [
        { required: true, message: "Tipo obrigatório", trigger: "change" },
      ],
      fornecedor: [
        {
          required: true,
          message: "Fornecedor obrigatório",
          trigger: "change",
        },
      ],
      ticketmedio: [
        {
          required: true,
          message: "Contabiliza ticket médio obrigatório",
          trigger: "change",
        },
      ],
      situacao: [
        { required: true, message: "Situação obrigatório", trigger: "change" },
      ],
      valorMaoDeObra: [
        {
          required: showMo.value && showValorMo.value == 1 ? true : false,
          message: "Valor da mão de obra obrigatório",
          trigger: "change",
        },
      ],
      valorHoraMO: [
        {
          
          trigger: "change",
          validator: (rule: any, value: any, callback: any) => {

            if(showValorMo.value == 2 && showMo.value){
                if(value == ''){
                callback(new Error("Valor da mão de obra obrigatório."));
              }
              if (removeCurrency(value) == 0){
                callback(new Error("É obrigatorio um valor."))
              }
            }            

            return true

          },
        },
      ],
      tempoMaoObra: [
        {
          validator: (rule: any, value: any, callback: any) => {
            console.log("🚀 ~ file: ServicoForm.vue:1428 ~ setup ~ value:", value)

            if(showValorMo.value == 2 && showMo.value){
              if(value == '') callback(new Error("O Tempo e obrigatório."))
              if(value == 0) callback(new Error("O Tempo não pode ser igual a zero."));
            } 

            return true
          },
          trigger: "change",
        },
      ],
      valorPremiacao: [
        {
          required: true,
          message: "Valor da premiação obrigatório",
          trigger: "change",
        },
      ],
    });

    const infoConcessionaria: any = ref([]);

    async function descricoes(codConcessionaria) {
      let response = await getDescricao(codConcessionaria);
      descricao.value = response["descricao"];
      infoConcessionaria.value = response["concessionaria"];
    }

    function removeCurrency(valor) {
      return valor.replace("R$ ", "").replace(".", "").replace(",", ".");
    }

    const submitForm = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          loadingButton.value = true;

          let valorMaoDeObra = '0.00'
          let valorHoraMO = '0.00'
          let tempoMaoObra = '0.00'
          if(showValorMo.value == 1){
            valorMaoDeObra = removeCurrency(form.valorMaoDeObra)
          }

          if(showValorMo.value == 2 ){
            valorHoraMO = removeCurrency(form.valorHoraMO)
            tempoMaoObra = form.tempoMaoObra
          }

          const postData = {
            codServico: form.codServico ? form.codServico : null,
            codConcessionaria: codConcessionaria.value,
            codCategoria: form.codCategoria,
            codPersonalizacao: form.codPersonalizacao
              ? form.codPersonalizacao
              : null,
            nome: form.nome,
            tipo: form.tipo,
            fornecedor: form.fornecedor,
            ticketmedio: form.ticketmedio,
            situacao: form.situacao,
            valorCompra: removeCurrency(form.valorCompra),
            intServico:
              multiplesPeca.value && multiplesPeca.value.length > 0
                ? multiplesPeca.value
                : peca.value,
            valorPeca: removeCurrency(form.valorPeca),
            valorPremiacao: removeCurrency(form.valorPremiacao),
            valorTotal: removeCurrency(valorTotalServico.value),
            intServicoMO:
              multiplesMo.value && multiplesMo.value.length > 0
                ? multiplesMo.value
                : maoObra.value,
            valorMaoDeObra: valorMaoDeObra,
            valorHoraMO: valorHoraMO,
            tempoMaoObra: tempoMaoObra,
            codwynns: form.codwynns ? form.codwynns : null,
            unidadescaixa: form.unidadescaixa ? form.unidadescaixa : null,
            estoque: form.estoque ? form.estoque : null,
            dataestoque: form.dataestoque ? form.dataestoque : null,
          };
          servicoAdd(postData)
            .then(function () {
              codServico ? showTimeAlert("Serviço editado com sucesso"):showTimeAlert("Serviço salvo com sucesso") ;
              goTo(`/cadastros/servicos/list`);
            })
            .catch(function (error) {
              showTimeAlert("Ocorreu um erro", "error");
            })
            .finally(() => {
              loadingButton.value = false;
            });
        } else {
          const inicio: any = document.getElementById("inicio");
          inicio?.scrollIntoView({ block: "center", inline: "nearest" });
        }
      });
    };

    const valorTotalServico: any = ref(0);

    watch(() => showValorMo.value, () => valorTotal() )

    function valorTotal() {
      let somaMo = 0
      let valorMo = 0
      if(showValorMo.value == 2){
        const calcValorHoraMo = isNaN(form.valorHoraMO)
        ? form.valorHoraMO.replace("R$ ", "").replace(".", "").replace(",", ".")
        : form.valorHoraMO;

         somaMo = calcValorHoraMo * form.tempoMaoObra
      }
      
      if(showValorMo.value == 1){
        valorMo = isNaN(form.valorMaoDeObra)
        ? form.valorMaoDeObra
            .replace("R$ ", "")
            .replace(".", "")
            .replace(",", ".")
        : form.valorMaoDeObra;
      }
      

      let valorPeca = isNaN(form.valorPeca)
        ? form.valorPeca.replace("R$ ", "").replace(".", "").replace(",", ".")
        : form.valorPeca;
      let soma = Number(valorPeca) + Number(valorMo) + Number(somaMo);
      valorTotalServico.value = soma.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }

    function mascaraTempo() {
      let mascara = form.tempoMaoObra
        .toString()
        .replace(/\B(?=(\d{2})+(?!\d))/g, ".");
      if (form.tempoMaoObra.toString().length >= 4) {
        mascara = form.tempoMaoObra
          .toString()
          .replace(".", "")
          .replace(/\B(?=(\d{2})+(?!\d))/g, ".");
      }
      form.tempoMaoObra = mascara;
    }

    const peca: any = ref([]);
    const maoObra: any = ref("");
    const multiplesPeca: any = ref([]);
    const multiplesMo: any = ref([]);

    function addPeca() {
      if (peca.value && peca.value.length > 0) {
        const pecaDuplicated = multiplesPeca.value.find(
          (pecaAux) => pecaAux == peca.value
        );

        if (!pecaDuplicated) {
          multiplesPeca.value.push(peca.value);
        }

        peca.value = "";

      }
    }

    function removePeca(index) {
      multiplesPeca.value.splice(index, 1);
    }

    function addMo() {
      if (maoObra.value && maoObra.value.length > 0) {
        const maoObraDuplicated = multiplesMo.value.find(
          (moAux) => moAux == maoObra.value
        );

        if (!maoObraDuplicated) {
          multiplesMo.value.push(maoObra.value);
        }

        maoObra.value = "";
      }
    }

    function removeMo(index) {
      multiplesMo.value.splice(index, 1);
    }

    function removeRowPeca() {
      form.valorPeca = 0;
      form.intServico = "";
      multiplesPeca.value = [];
      valorTotal();
      showPeca.value = false;
    }

    function removeRowMo() {
      form.tempoMaoObra = '0.00';
      form.valorMaoDeObra = 0;
      form.intServicoMO = "";
      form.valorHoraMO = '0.00'
      multiplesMo.value = [];
      valorTotal();
      showMo.value = false;
    }

    const opcaoPeca = ref("");
    const textoPeca = ref("");
    const tipoPeca = ref("1");
    const loadingButtonModal = ref(false);
    const resultadoNaoEncontrado = ref(false);
    const buscaPecas: any = ref([]);

    function resetModal() {
      buscaPecas.value = [];
      textoPeca.value = "";
      opcaoPeca.value = "";
      resultadoNaoEncontrado.value = false;
    }

    function buscarPeca(pecaCodigo = "") {
      resultadoNaoEncontrado.value = false;
      textoPeca.value = pecaCodigo ? pecaCodigo : textoPeca.value;
      opcaoPeca.value = opcaoPeca.value ? opcaoPeca.value : "1";

      if (!textoPeca.value) {
        return;
      }

      loadingButtonModal.value = true;

      const postDataBusca: any = {
        marca: store.getters.concessionariaSelectedInfo.marca,
        cnpj: store.getters.concessionariaSelectedInfo.cnpj,
        texto: textoPeca.value,
        tipo: tipoPeca.value,
        opcao: opcaoPeca.value,
        dms: infoConcessionaria.value.dms,
        urlIntegracao: infoConcessionaria.value.urlIntegracao,
      };

      ApiService.postIntegracao(`/oficinadigitalconsultardms`, postDataBusca)
        .then(function (response) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            buscaPecas.value = response.data;
          } else {
            resultadoNaoEncontrado.value = true;
            buscaPecas.value = [];
          }

          buscaPecas.value.forEach((peca) => {
            if (peca.estoque) {
              peca.estoque.replace(".", "");
              peca.estoque = parseFloat(peca.estoque).toFixed(0);
            } else {
              peca.estoque = 0;
            }
            if (peca.valor) {
              peca.valor = parseFloat(peca.valor).toLocaleString("pt-Br", {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
              });
            } else {
              peca.valor = "Sem info";
            }

            if (peca.tempo) {
              peca.tempo = parseFloat(peca.tempo).toFixed(2);
            } else {
              peca.tempo = "Sem info";
            }
          });
        })
        .catch(function (error) {})
        .finally(() => {
          loadingButtonModal.value = false;
        });
    }

    function pushPeca(peca) {
      multiplesPeca.value.push(peca.referencia);
      form.valorPeca = peca.valor;
      modalBuscarPeca.value?.hide();
    }

    function pushPecaMO(peca) {
      if (showValorMo.value == 2) {
        multiplesMo.value.push(peca.referencia);
        form.tempoMaoObra = peca.tempo;
      } else {
        multiplesMo.value.push(peca.referencia);
        form.valorMaoDeObra = peca.valor;
      }

      modalBuscarPecaMO.value?.hide();
    }

    const router = useRouter();

    function goTo(url) {
      router.push(url);
    }

    return {
      concessionariaNome: computed(
        () => store.getters.concessionariaSelectedInfo.nomeFantasia
      ),
      form,
      showMo,
      ruleFormRef,
      rules,
      formSize,
      submitForm,
      loadingButton,
      showPeca,
      showValorMo,
      money,
      valorTotal,
      valorTotalServico,
      dominio_gestao,
      descricao,
      mascaraTempo,
      ptBr,
      addPeca,
      peca,
      multiplesPeca,
      removePeca,
      opcaoPeca,
      textoPeca,
      resetModal,
      loadingButtonModal,
      resultadoNaoEncontrado,
      buscarPeca,
      buscaPecas,
      modalBuscarPeca,
      modalBuscarPecaMO,
      tipoPeca,
      pushPeca,
      pushPecaMO,
      addMo,
      removeMo,
      multiplesMo,
      maoObra,
      removeRowPeca,
      removeRowMo,
      goTo,
      animate,
    };
  },
});
